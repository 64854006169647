import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import coloredMap from '../../content/images/keyFeatures/coloredMap_white.webp';
import editMarker from '../../content/images/keyFeatures/editMarker_white.webp';
import filtersImg from '../../content/images/keyFeatures/filters.webp';
import navigationImg from '../../content/images/keyFeatures/navigation.webp';

const keyFeatures = [
    {
        title: 'Inteligentne mapy',
        content: 'Personalizuj lokalizacje, oznaczając etapy prac budowlanych różnymi ikonami i kolorami.',
        moreContent: 'Dzięki inteligentnym mapom w NaviBud, możesz łatwo wizualizować i śledzić postępy różnych projektów budowlanych. Oznaczaj różne etapy budowy za pomocą unikalnych ikon i kolorów, co pozwala na szybkie i intuicyjne zrozumienie statusu każdego projektu. Ułatwia to planowanie i zarządzanie wieloma inwestycjami jednocześnie.',
        img: coloredMap,
    },
    {
        title: 'Filtruj i analizuj dane',
        content: 'Precyzyjnie wybieraj istotne informacje dla Twojej firmy dzięki zaawansowanym filtrom.',
        img: filtersImg,
        moreContent: 'Zaawansowane filtry NaviBud umożliwiają dokładne sortowanie i analizę danych dotyczących budów. Możesz wybrać konkretne statusy projektów, regiony czy typy inwestycji, co pozwala na szybkie znalezienie kluczowych informacji. To narzędzie pozwala lepiej zrozumieć rynek i podejmować bardziej świadome decyzje biznesowe.'
    },
    {
        title: 'Centralizacja informacji',
        content: 'Gromadź wszystkie dane o budowie, inwestorach, notatkach, ofertach i zdjęciach w jednym miejscu.',
        img: editMarker,
        moreContent: 'NaviBud centralizuje wszystkie istotne informacje dotyczące projektów budowlanych w jednym miejscu. Możesz przechowywać dane o budowach, inwestorach, notatki, oferty oraz zdjęcia, co pozwala na łatwy dostęp i zarządzanie informacjami. To znacząco usprawnia przepływ pracy i zwiększa efektywność operacyjną Twojej firmy.'
    },
    {
        title: 'Planowanie tras',
        content: 'Wyznaczaj trasy do wielu lokalizacji i podążaj za nimi dzięki integracji z Google Maps.',
        img: navigationImg,
        moreContent: 'Planowanie tras w NaviBud umożliwia efektywne planowanie wizyt na placach budowy. Dzięki integracji z Google Maps możesz wyznaczać optymalne trasy do wielu lokalizacji, co pozwala na oszczędność czasu i paliwa. To narzędzie pomaga zoptymalizować logistyki, zwiększając produktywność Twojego zespołu.'
    }
];

const KeyFeaturesSection: React.FC = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState<null | typeof keyFeatures[0]>(null);

    const handleClickOpen = (feature: typeof keyFeatures[0]) => {
        setSelectedFeature(feature);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFeature(null);
    };

    return (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography variant="h3" component="h2" gutterBottom
                sx={{
                    fontSize: {
                        xs: '1.8rem',
                        md: '2.5rem',
                        lg: '3.0rem'
                    },
                    mb: 5,
                    textAlign: 'center'
                }}>
                Kluczowe Funkcje NaviBud
            </Typography>
            <Typography variant="body1"
                sx={{
                    color: '#333',
                    lineHeight: 1.8,
                    fontSize: {
                        xs: '1.0rem',
                        md: '1.2rem'
                    },
                    mb: '60px',
                    textAlign: 'center'
                }}>
                To nie tylko mapa z punktami, to kompleksowe narzędzie z
                funkcjonalnościami, które nadają nowy wymiar zarządzaniu działaniami
                sprzedażowymi w branży budowlanej.
                <br />Przekształć swoje podejście do zdobywania
                klientów dzięki tym funkcjom:
            </Typography>
            {keyFeatures.map((feature, index) => (
                <Grid container spacing={3} key={index} sx={{ width: '100%', marginLeft: 0, mb: 4, backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff', paddingRight: 4 }}>
                    <Grid item xs={12} md={8} padding={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Box
                            component="img"
                            sx={{
                                width: isSmallScreen ? '100%' : '80%',
                                height: isSmallScreen ? 'auto' : 'auto',
                                maxWidth: '100%',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                }
                            }}
                            src={feature.img}
                            alt={feature.title}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: theme.spacing(3) }}>
                        <Typography variant="h5" component="p" sx={{ mb: 2, color: '#0e4999', fontWeight: 'bold' }}>
                            {feature.title}
                        </Typography>
                        <Typography variant="body1" textAlign={'justify'}>
                            {feature.content}
                        </Typography>
                        <Button
                            size="small"
                            startIcon={<InfoIcon />}
                            sx={{ fontSize: '0.875rem', color: '#ff7f11', mt: 2, mb: 4 }}
                            onClick={() => handleClickOpen(feature)}
                        >
                            Przeczytaj więcej...
                        </Button>
                    </Grid>
                </Grid>
            ))}

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{selectedFeature?.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {selectedFeature?.moreContent}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zamknij
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default KeyFeaturesSection;
