import React, { forwardRef } from 'react';
import { Box, Button, TextField, Typography, Grid, Snackbar, Alert, FormControlLabel, Checkbox, Backdrop, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertColor } from '@mui/material/Alert';
import { colors } from '../../theme';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email'

interface FormValues {
  email: string;
  message: string;
  acceptedPolicy: boolean;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Niepoprawny adres email').required('Email jest wymagany'),
  message: Yup.string().required('Wiadomość jest wymagana'),
  acceptedPolicy: Yup.boolean()
    .required('Musisz zaakceptować politykę prywatności.')
    .oneOf([true], 'Musisz zaakceptować politykę prywatności.')
});

const ContactFormSection = forwardRef<HTMLDivElement>((props, ref) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('success');
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
      acceptedPolicy: false
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      handleFormSubmit(values, setSubmitting, resetForm);
    }
  });

  const handleFormSubmit = async (values: FormValues, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    try {
      const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.CONTACT}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
      });

      if (!response.ok) throw new Error('Failed to send message');

      setSnackbarMessage('Wiadomość została wysłana!');
      setSnackbarSeverity('success');
      resetForm();
    } catch (error) {
      setSnackbarMessage('Wystąpił błąd podczas wysyłania wiadomości.');
      setSnackbarSeverity('error');
    } finally {
      setSubmitting(false);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={2} ref={ref} id="contactSection"
      sx={{
        ml: 0,
        width: '100%',
        mt: 4,
        mb: 4
      }}>



      <Grid item xs={12} md={6} sx={{ bgcolor: colors.lightGray, pr: 8 }}>
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            paddingLeft: 0,
          }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Skontaktuj się z nami
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Skontaktuj się z nami bezpośrednio lub poprzez formularz.
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            Jesteśmy tutaj, aby odpowiedzieć na Twoje pytania i omówić najbliższą przyszłość z naszą aplikacją.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PhoneIcon sx={{ color: colors.warmOrange, mr: 2 }} />
            <Box>
              <Typography variant="body2">Telefon:</Typography>
              <Typography variant="h6"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1rem', md: '1.25rem' },
                }}
              >
                +48 453 097 788</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <EmailIcon sx={{ color: colors.warmOrange, mr: 2 }} />
            <Box>
              <Typography variant="body2">Email:</Typography>
              <Typography variant="h6"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1rem', md: '1.25rem' },
                }}
              >kontakt@navi-bud.pl</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>



      <Grid item xs={12} md={6} sx={{ padding: 2, display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            paddingLeft: 0,
          }}>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Formularz kontaktowy
            </Typography>
            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              name="message"
              label="Wiadomość"
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptedPolicy"
                  checked={formik.values.acceptedPolicy}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="Akceptuję politykę prywatności"
            />
            {formik.touched.acceptedPolicy && formik.errors.acceptedPolicy && (
              <Typography color="error" variant="body2">
                {formik.errors.acceptedPolicy}
              </Typography>
            )}
            <Button type="submit" variant="contained" fullWidth sx={{ bgcolor: '#ff7f11', borderRadius: 0 }}>
              Wyślij
            </Button>
            {loading && (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </form>
        </Box>
      </Grid>


      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
});

export default ContactFormSection;
