import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import { colors } from '../../theme';

const TestAppBottomSection = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ padding: 4, backgroundColor: colors.warmOrange, textAlign: 'center', my: 6, borderRadius: 0 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={8} sx={{ textAlign: {xs: 'center', md: 'left'} }}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                        Wypróbuj naszą aplikację już teraz!
                    </Typography>
                    <Typography sx={{ mb: { xs: 3, md: 0 }, color: '#ffffff' }}>
                        Za darmo przez 7 dni, bez zobowiązań.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            borderRadius: 0,
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                            },
                            fontWeight: 'bold'
                        }}
                        onClick={() => navigate('/testuj-aplikacje')}
                    >
                        Załóż darmowe konto
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TestAppBottomSection;
